<template>
    <div class="ele-process">
        <Header :svgColor="'#fff'" :back="true">
            <span slot="headText">支付宝支付流程说明</span>
        </Header>
        <div class="ele-content">
            <swiper class="swiper" ref="mySwiper" :options="swiperOption">
                <swiper-slide>
                    <div class="ele-card">
                        <h5>1、请将【支付宝】App <span>更新至最新版本</span></h5>
                        <h5>
                            2、在支付过程中，不要点击任意按钮
                        </h5>
                        <div class="card-img">
                            <img style="width: 65%" src="../../assets/img/iphone.png" />
                        </div>
                        <div class="card-text">
                            扣款渠道无论显示个人“余额宝”、“银行卡”、“花呗”，均
                            <span>不会扣减</span>
                            个人账户，无需点击切换。
                        </div>
                        <div class="foot-btn" @click="goNext">
                            下一页
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="ele-card">
                        <h5>
                            1、点击<span> [支付] </span>按钮。
                        </h5>
                        <h5>2、出示支付二维码。<span>商家扫码</span> 后，付款出现
                            <span>"0.00"</span>元即可（如下图所示）
                        </h5>
                        <div class="card-img">
                            <img src="../../assets/img/eleProcess.jpg" />
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" id="test" v-model="check" class="test" />
                            <label for="test"></label>
                            <div>下次不再提示</div>
                        </div>
                        <div class="foot-btn" @click="submit">
                            支付
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="bottom-container">
                <div class="swiper-pagination swiper-pagination-bullets">
                    <span
                        class="swiper-pagination-bullet"
                        v-for="item in [0, 1]"
                        :key="item"
                        :class="{'swiper-pagination-bullet-active':page === item}"
                    ></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { Colors } from '@/utils/colors.js'
import {
	SmartStorage
} from 'smart-core-util';
import { getCookie } from 'tiny-cookie'
export default {
    data() {
        return {
            check: false,
            navBackgroundColor: {},
            swiperOption: {
                spaceBetween: 20,
                on: {
                    slideChange: () => {
                        this.page = this.$refs.mySwiper.$swiper.activeIndex;
                    },
                },
                slidesPerView: 1.2,
                centeredSlides: true,
            },
            page: 0,
        };
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive,
    },
    computed: {
        ...mapGetters(["orderDetail", "memsg", "event"]),
    },
    mounted() {
        this.navBackgroundColor = Colors
    },
    methods: {
        goPre() {
            this.page -= 1;
            this.$refs.mySwiper.$swiper.slideTo(this.page, 300, true); //切换到第一个slide，速度为1秒
        },
        goNext() {
            this.page += 1;
            this.$refs.mySwiper.$swiper.slideTo(this.page, 300, true); //切换到第一个slide，速度为1秒
        },
        submit() {
            let _this = this
            if (!this.check && !SmartStorage.get('isH5')) {
                // this.iJsBridge.call({
                //     method: "SMDataStorage.setItem",
                //     postData: { ["tip"]: true },
                // });

                if (window.flutter_inappwebview) {
                    let data = {
                        key: "tip",
                        value: true,
                    };
                    window.flutter_inappwebview.callHandler(
                        "SmStorage.setBool",
                        JSON.stringify(data)
                    );
                } else {
                    this.iJsBridge.call({
                        method: "SMDataStorage.setItem",
                        postData: { ["tip"]: true },
                    });
                }
            }
            if (this.$route.query.see) {
                this.$router.push("/elePayDetail?tip=true");
            } else {
                let orderNo= '';
                // 协办会
                if(SmartStorage.get('isH5')){
                    orderNo = this.$route.query.orderNo
                }else{
                    orderNo = this.orderDetail.orderNo
                }
                let params = {
                    uNo: this.memsg.userId,
                    orderNo: orderNo,
                    callBackUrl:'https://ipage.smartmice.cn/externalCallback?rnd='+ new Date().getTime(),
                    phone: this.memsg.phoneNumber,
                };
                // huarun 
                if(SmartStorage.get('isH5') || this.event.tenant_name == '会引擎-huarun'){
                    params.callBackUrl = SmartStorage.get('Uris').Uris['webapp-imeal'] + `/elepaydetail?tip=true&submitFlag=true&orderNo=${orderNo}&sessionId=${SmartStorage.get('sessionId')}`
                    // params.callBackUrl = 'http://192.168.1.171:8080' + "/elepaydetail?tip=true&submitFlag=true&orderNo=" + this.$route.query.orderNo
                }
                miceService.getElePayPage(params).then((res) => {
                    if (res && res.success) {
                        const tenant_code = this.event.tenant_code || "roche";
                        if(SmartStorage.get('isH5') || this.event.tenant_name == '会引擎-huarun'){
                            window.location.href = res.content.url
                            return
                        }
                        const options = {
                            method: "SMGeneral.openNewWebSiteForResult",
                            postData: {
                                loadUrl: res.content.url,
                                browserMode: {
                                    navBarVisibilty: true,
                                    titleTextColor: this.navBackgroundColor[
                                        tenant_code
                                    ],
                                    navBackgroundColor: this.navBackgroundColor[
                                        tenant_code
                                    ],
                                },
                            },
                            callback: _this.openEleWebSiteCallback,
                        };
                        _this.iJsBridge.call(options);
                    }
                });
            }
        },
        async openEleWebSiteCallback() {
            if(this.$route.name == 'eleProcess'){
                this.$router.push("/elePayDetail?tip=true&submitFlag=true");
            }
        },
    },
};
</script>
<style lang="scss">

.ele-process {
    overflow: scroll !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    * {
        text-align: left;
    }
    .headContent3 {
        text-align: center !important;
    }
    .ele-content {
        height: 100%;
        position: relative;
        background: var(--themeColor);
        margin-top: -0.1rem;
        padding-top: 0.24rem;

        .bottom-container {
            padding: 0 0.25rem 0.25rem 0.25rem;
            box-sizing: border-box;
            background: var(--themeColor);
        }
        .ele-card {
            padding: 0.2rem;
            background: #fff;
            border-radius: 0.13rem;
            margin-bottom: 0.12rem;

            h5 + h5 {
            margin-top: 0.1rem;
        }

        h5 {
            color: #9a9292;
            font-size: 0.16rem;
            margin: 0;
            span {
                color: #ff0000;
            }
        }

            .card-img {
                font-size: 0.14rem;
                margin-top: 0.18rem;
                text-align: center;

                img {
                    width: 100%;
                }
            }

            .card-text {
                font-size: 0.14rem;
                span {
                    color: #D7290F;
                    font-weight: 700;
                }
            }
        }

        .foot-btn {
            border-radius: 0.05rem;
            background: #ff0000;
            height: 0.45rem;
            font-size: 0.16rem;
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: 0.45rem;
            margin-top: 0.1rem;
        }
        .checkbox {
            font-size: 0.14rem;
            margin-top: 2.5rem;
            // text-align: center;
            display: flex;
            justify-content: center;
            color: #D7290F;
            height: 0.25rem;
        }

        @media (max-width: 767px) {
            .checkbox {
                margin-top: 0.9rem;
            }
        }

        label {
            font-size: 0.14rem;
            color: #D7290F;
        }
        #test {
            visibility: hidden;
        }

        #test + label {
            width: 0.18rem;
            height: 0.18rem;
            background-color: #fff;
            display: block;
            margin-right: 0.1rem;
            border-radius: 4px;
            cursor: pointer;
            overflow: hidden;
            border:1px solid #D7290F;
        }
        #test:checked + label:before {
            content: "√"; /*这里设置选中之后的显示内容*/
            width: 0.18rem;
            height: 0.18rem;
            display: block;
            color: #e60012;
            text-align: center;
            font-weight: bolder;
            line-height: 0.18rem;
        }
    }
    .flex-container {
        display: flex;
        justify-content: space-between;

        .foot-btn {
            width: 45%;
        }
    }
    .swiper-pagination {
        position: relative !important;
        widows: 100%;
        font-size: 14px;
        margin: -0.05rem 0 0.12rem 0;
        text-align: center;

        .swiper-pagination-bullet {
            background: #fff !important;
            opacity: 0.4 !important;
            margin: 0 0.04rem;
            width: 0.06rem;
            height: 0.06rem;
        }

        .swiper-pagination-bullet-active {
            opacity: 1 !important;
            width: 0.1rem;
            border-radius: 40%;
        }
    }
}
</style>